<template>
  <el-row class="login">
    <div class="content">
      <div class="loginInput">

        <!-- 演示 -->
        <img src="../assets/production/login-logo.png" alt />
        <span>AIOS智慧运营管理系统</span>

        <!-- 武汉 -->
        <!-- <img src="../assets/production/login-logo-wuhan.png" alt />
        <span>武汉大悦城商管平台</span> -->

        <!-- 影之宝 -->
        <!-- <img src="../assets/production/login-logo-jianguo.png" alt /> 
        <span>尖果互动运营管理平台</span>  -->
        
        <div class="yourName">
          <svg t="1586503943181"
               class="icon"
               viewBox="0 0 1024 1024"
               version="1.1"
               xmlns="http://www.w3.org/2000/svg"
               p-id="3096"
               width="16"
               height="16">
            <path d="M327.111111 443.733333c73.955556 73.955556 187.733333 96.711111 284.444445 56.888889 96.711111-39.822222 159.288889-136.533333 159.288888-238.933333 0-108.088889-62.577778-199.111111-159.288888-238.933333-62.577778-28.444444-136.533333-28.444444-199.111112 0C332.8 51.2 270.222222 125.155556 253.155556 210.488889s11.377778 176.355556 73.955555 233.244444z m386.844445 136.533334H304.355556C179.2 580.266667 82.488889 682.666667 82.488889 802.133333S179.2 1024 304.355556 1024h415.288888c119.466667 0 221.866667-96.711111 221.866667-221.866667s-102.4-221.866667-227.555555-221.866666z"
                  p-id="3097"
                  fill="#C0C4CC" />
          </svg>
          <el-input placeholder="账号"
                    v-model="phone"
                    @keyup.enter.native="login"></el-input>
        </div>
        <div class="yourName password">
          <svg t="1586503692366"
               class="icon"
               viewBox="0 0 1024 1024"
               version="1.1"
               xmlns="http://www.w3.org/2000/svg"
               p-id="2149"
               width="16"
               height="16">
            <path d="M853.932 349.717H762.71c0-146.514-111.981-258.495-254.702-258.495-142.921 0-254.703 111.981-254.703 258.495h-91.421C161.884 173.86 316.98 0 507.808 0c190.827 0 346.124 173.86 346.124 349.717z m115.774 149.907c0-50.302-40.92-91.222-91.222-91.222H147.512c-50.302 0-91.222 40.92-91.222 91.222v433.154c0 50.302 40.92 91.222 91.222 91.222h730.773c50.301 0 91.221-40.92 91.221-91.222V499.624h0.2z m-384.45 338.739c0 37.726-30.54 68.466-68.465 68.466h-9.781c-37.727 0-68.467-30.54-68.467-68.466V652.725c0-37.726 30.54-68.466 68.467-68.466h9.78c37.727 0 68.467 30.54 68.467 68.466v185.638z m0 0"
                  p-id="2150"
                  fill="#C0C4CC" />
          </svg>
          <el-input placeholder="密码"
                    v-model="password"
                    type="password"
                    @keyup.enter.native="login"></el-input>
        </div>
        <div class="form-group yourName"
             style="display: flex;">
            <div class="groupBox">
              <span class="codeTitle">验证码：</span>
              <input type="text"
                     id="code"
                     v-model="code"
                     class="code"
                     @keydown.enter="login"
                     placeholder="请输入您的验证码" />
            </div>
            <img :src="imageBase64" @click="refreshCode" alt="验证码" class="code-img" />
        </div>
<!--        <div class="swtich-box">-->
<!--          <el-switch v-model="showCode" active-color="#5c6be8"></el-switch>-->
<!--          验证码-->
<!--        </div>-->
        <div class="btn"
             @click="login">
          <img src="../assets/login-btn.png"
               alt />
        </div>
      </div>
      <img class="bg"
           src="../assets/login-content-bg.png" />
    </div>
  </el-row>
</template>

<script>
import {initRoute} from "../router/initRoute";
import {resetRouter} from '@/router/index.js'
import {getRsaCode} from '../utils/utils'
export default {
  data () {
    return {
      codeUuid: '',
      imageBase64: '',
      password: null,
      phone: null,
      code: '', // text框输入的验证码
    }
  },
  computed: {},

  created () { },
  mounted () {
    resetRouter()
    this.getPublicKey()
    this.getPictureCode()
  },
  methods: {
    getPublicKey () {
      this.$api.sysapi.getPublicKey().then(res => {
        if (res.code === 200) {
          let publicKey = res.data.publicKey || ''
          localStorage.setItem("publicKey", publicKey);
        }
      })
    },
    getPictureCode () {
      this.$api.sysapi.getPictureCode().then(res => {
        if (res.code === 200) {
          let {imageBase64, codeUuid} = res.data
          this.imageBase64 = 'data:image/png;base64,' + imageBase64
          this.codeUuid = codeUuid
        }
      })
    },
    refreshCode() {
      this.getPictureCode()
    },
    login () {
      if (!this.phone) {
        this.$message.error('账号不能为空')
        return
      }
      if (!this.password) {
        this.$message.error('密码不能为空')
        return
      }
      if (!this.code) {
        this.$message.error('验证码不能为空')
        return
      }
      const _this = this
      const obj = {
        username: _this.phone,
        password: getRsaCode(_this.password),
        code: this.code,
        codeUuid: this.codeUuid
      }
      _this.$api.sysapi.setLoginV2(obj).then(({ code, data, msg }) => {
        if (code === 200) {
          if(!data.sysModule){
            return this.$message.error('当前用户没有访问权限，请联系管理员')
          }
          var exp = new Date()
          var Days = 7
          exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000)
          document.cookie = `DK_TOKEN=${data.token
            };expires=${exp.toGMTString()}`
          localStorage.setItem('sysModule', JSON.stringify(data.sysModule))
          let permission = data.sysModule.child.filter(item => item.id === 2 /*'后台权限'*/);
          let pathName
          if(permission&&permission.length){   //  有后台权限
            const _menuList = initRoute(data.sysModule)
            delete data.sysModule
            localStorage.setItem('userInfo', JSON.stringify(data))
            localStorage.setItem('menuList', JSON.stringify(_menuList))
            pathName = _menuList.filter(e=>e.meta.isShow)[0].path;
            _this.$router.push({ path: pathName })
          }else{   //  无权限
            _this.$message.error('当前用户没有任何权限，请联系管理员分配权限')
          }
        } else {
          _this.$message.error(msg)
        }
      }).catch((err) => {
        if (err.code === 400) {
          this.getPictureCode()
        }
      })
    },
  },
  watch: {}
}
</script>
<style lang="scss" scoped>
.login {
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/login-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 1200px;

  .content {
    position: relative;
    .loginInput {
      width: 45%;
      height: 90%;
      position: absolute;
      top: 0;
      left: 39%;
      right: 0;
      bottom: 0;
      z-index: 9;
      margin: auto;
      padding-top: 80px;
      box-sizing: border-box;
      text-align: center;
      img {
        width: 200px;
      }
      span {
        display: block;
        margin-top: 30px;
        font-size: 28px;
      }
      .yourName {
        position: relative;
        width: 300px;
        margin: 40px auto 0;
        // border-bottom: 1px solid #dcdfe6;
        svg {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 10px;
          margin: auto;
          z-index: 9;
        }
      }
      .password {
        margin-top: 40px;
      }
      .swtich-box{
        margin-top: 10px;
        margin-bottom: -18px;
      }
      .btn {
        margin-top: 25px;
        img {
          width: 250px;
          cursor: pointer;
        }
      }
    }
    .form-group {
      .groupBox {
        display: flex;
        align-items: center;
        input {
          outline: none;
          padding-left: 5px;
          box-sizing: border-box;
        }
        input::placeholder {
          font-size: 12px;
          font-family: Arial;
          letter-spacing: 1px;
          color: #ccc !important;
        }
      }
      .codeTitle {
        width: 50px;
        font-size: 12px;
        margin-top: 0;
        color: #999;
      }
      .code {
        border-radius: 5px;
      }
    }
    .code {
      width: 115px;
      height: 31px;
      border: 1px solid rgba(186, 186, 186, 1);
      margin-right: 10px;
    }
    .login-code {
      cursor: pointer;
    }
    .code-img {
      width: 120px;
      height: 38px;
      cursor: pointer;
    }
    .bg {
      transform: scale(0.85);
    }
  }
}
</style>
