<template>
  <div class="loginbox">
    <img src="../assets/404.png" alt="">
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  components: {
  },
  created () {
  },
  mounted () {

  },
  computed: {},
  methods: {

  },
  watch: {}
}
</script>

<style scoped lang='scss'>

.loginbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  img {
    width: 450px;
  }
}
</style>
